
/* eslint-disable */

import { ref, reactive, onMounted, provide } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";

const modelList = [
  { 
		id: 1, 
		imageSrc: require("@/assets/images/placeholder/librarydata1.jpg"), 
		name: 'Glaucoma', 
		info: 'detecting glaucoma from fundus photographs', 
		file: 'IMAGE', 
		model: 'classification', 
		version: 'v0.1 on 0000-00-00',
		button: 'GET MODEL', 
		current: false,
	},
	{ 
		id: 2, 
		imageSrc: require("@/assets/images/placeholder/librarydata1.jpg"), 
		name: 'Diabetic Retinopathy', 
		info: 'detecting the severity of diabetic retinopathy from fundus photographs', 
		file: 'IMAGE', 
		model: 'classification', 
		version: 'v0.1 on 0000-00-00',
		button: 'GET MODEL', 
		current: false,
	},
	{ 
		id: 3, 
		imageSrc: require("@/assets/images/placeholder/librarydata2.jpg"), 
		name: 'Lung Opacity', 
		info: 'detecting lung opacity from chest X-rays', 
		file: 'IMAGE', 
		model: 'classification', 
		version: 'v0.1 on 0000-00-00',
		button: 'START INFERENCE', 
		current: true,
	},
	{ 
		id: 4, 
		imageSrc: require("@/assets/images/placeholder/librarydata1.jpg"), 
		name: 'Anterior Cruciate Ligament(ACL) Tear', 
		info: 'detecting ACL tear from knee MRIs', 
		file: 'IMAGE', 
		model: 'classification', 
		version: 'v0.1 on 0000-00-00',
		button: 'GET MODEL', 
		current: false,
	},
	{ 
		id: 5, 
		imageSrc: require("@/assets/images/placeholder/librarydata1.jpg"), 
		name: 'Bone Age', 
		info: 'predicting bone age from hand X-rays', 
		file: 'IMAGE', 
		model: 'classification', 
		version: 'v0.1 on 0000-00-00',
		button: 'GET MODEL', 
		current: false,
	},
]

export default defineComponent({
	name: "modelList",
	components: {
	},
	setup() {
		return {
			modelList,
		};
	},
});
